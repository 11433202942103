<template>

  <div ref="mosaic" class="mosaic">
    <svg viewBox="0 0 200 200" width="100%" height="100%" class="mosaic__mask">
      <defs>
        <mask v-if="layout === 'a'" fill="black" id="reversemask_a">
          <rect fill="white" width="100%" height="100%" />
          <path d="m15.57991,120.2608l76.59369,-15.30465l21.34778,72.98533l-107.24615,16.81159l9.30467,-74.49227z" />
          <path d="m3.40598,24.00016c39.63743,-6.52183 154.60828,-14.71034 194.24571,-21.23217l-52.53629,86.59403l-97.44886,19.47817l-44.26056,-84.84003z" />
          <path d="m122.42021,188.66635c-7.89863,-27.97103 -16.46394,-56.94206 -24.36258,-84.91309l92.78241,-18.14489l-29.36226,105.89822l-39.05757,-2.84024z" />
        </mask>
        <mask v-else-if="layout === 'p'" fill="black" id="reversemask_p">
          <rect fill="white" width="100%" height="100%" />
          <g transform="matrix(1,0,0,1,-1,-7)">
              <path d="M7.043,95.584L5.395,166.794L39.683,182.1L60.903,121.7L7.043,95.584Z"/>
          </g>
          <g transform="matrix(1,0,0,1,36,6)">
              <path d="M92.513,131.26L72.834,188.349L130.856,187.791L145.056,140.004L92.513,131.26Z"/>
          </g>
          <g transform="matrix(1,0,0,1,5,2)">
              <path d="M36.369,175.758L99.425,191.791L163.65,4.663L100,-0.714L36.369,175.758Z"/>
          </g>
        </mask>
        <mask v-if="layout === 'b'" fill="black" id="reversemask_b">
          <rect fill="white" width="100%" height="100%" />
          <path d="M4.489,3.502L5.784,112.455L89.689,89.101L89.335,49.778L4.489,3.502Z"/>
          <path d="M5.901,196.477L91.224,187.695L89.689,95.322L18.577,114.493L5.901,196.477Z"/>
          <path d="M94.224,34.643L96.224,150.391L160.294,198.477L197.842,1.502L94.224,34.643Z"/>
        </mask>
        <mask v-else-if="layout === 'c'" fill="black" id="reversemask_c">
          <rect fill="white" width="100%" height="100%" />
          <g transform="matrix(1,0,0,1,70.1864,84.0219)">
              <path d="M-13.726,-15.601L-65.286,37.948L126.687,33.966L80.971,-14.674L-13.726,-15.601Z"/>
          </g>
          <path d="M38.734,191.528L150.083,190.656L195.873,123.97L6.901,127.419L38.734,191.528Z"/>
          <g transform="matrix(1,0,0,1,0,-3)">
              <path d="M5.901,22.956L56.357,65.924L151.473,66.537L196.873,6.341L5.901,22.956Z"/>
          </g>
        </mask>
      </defs>
      <rect fill="black" width="100%" height="100%" :mask="`url(#reversemask_${layout})`" />
    </svg>
    <img :src="image" class="mosaic__image" :class="{'mosaic__image--has-loaded': hasLoaded}" :alt="alt" :style="styleImage" @load="onLoad" loading="lazy">
  </div>

</template>

<script>

const isTranformObject = (object) => {
  return (typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 's') && typeof object.s === 'number' &&
    Object.prototype.hasOwnProperty.call(object, 'x') && typeof object.x === 'number' &&
    Object.prototype.hasOwnProperty.call(object, 'y') && typeof object.y === 'number')
}

export default {
  name: 'MosaicImage',
  props: {
    image: {
      type: String,
      required: true,
    },
    start: {
      type: Object,
      validator(val){
        return isTranformObject(val)
      },
      default: () => ({s: 1, x: 0, y: 0})
    },
    end: {
      type: Object,
      validator(val){
        return isTranformObject(val)
      },
      default: () => ({s: 1, x: 0, y: 0})
    },
    layout: {
      type: String,
      default: 'a',
      validator(val){
        return ['a', 'b', 'c', 'p'].includes(val)
      }
    },
    alt: {
      type: String,
      required: true,
    }
  },
  data(){
    return {
      animationProgress: 0,
      isVisible: false,
      observer: null,
      hasLoaded: false,
    }
  },
  computed: {
    styleImage(){
      const scale = this.isVisible ? this.end.s : this.start.s
      const translateX = this.isVisible ? this.end.x : this.start.x
      const translateY = this.isVisible ? this.end.y : this.start.y
      return {
        transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`
      }
    }
  },
  mounted(){
    this.observer = new window.IntersectionObserver(([entry]) => this.isVisible = entry.isIntersecting, {root: null, threshold: 0.2,})
    this.observer.observe(this.$el);
  },
  beforeDestroy(){
    this.observer.unobserve(this.$el)
  },
  methods: {
    onLoad(){
      this.hasLoaded = true
    }
  }
}
</script>

<style scoped lang="sass">
.mosaic
  height: 300px
  width: 300px
  overflow: hidden
  position: relative

  @media (min-width: 450px)
    height: 400px
    width: 400px

  &::before
    content: ''
    display: block
    position: absolute
    width: 100%
    height: 100%
    left: 0
    right: 0
    top: 0
    bottom: 0
    z-index: 3
    box-shadow: inset 0 0 0 2px #000

  &__mask
    position: absolute
    z-index: 2
    width: 100%
    height: 100%

  &__image
    width: 100%
    height: 100%
    object-fit: cover
    transition: transform 10s ease
    display: block
    opacity: 0

    &--has-loaded
      animation: 4.5s appear forwards

@keyframes appear
  from
    opacity: 0
  to
    opacity: 1

</style>
